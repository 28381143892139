import { render, staticRenderFns } from "./BattlesHeaderOverview.vue?vue&type=template&id=17486bd1&scoped=true"
import script from "./BattlesHeaderOverview.vue?vue&type=script&lang=js"
export * from "./BattlesHeaderOverview.vue?vue&type=script&lang=js"
import style0 from "./BattlesHeaderOverview.vue?vue&type=style&index=0&id=17486bd1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17486bd1",
  null
  
)

export default component.exports